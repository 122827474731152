import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import logo from '../../logo.svg';
import '../../App.css';


const useStyles = makeStyles(() => ({
    welcomeText: {
        marginTop: '20px',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: '600'
    },
    loadingPageTextText: {
        marginTop: '10px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '400'
    },
}))

  export default function LoadingComponent({page}) {
    const classes  = useStyles()
    
    return(
        <div className="App">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <Typography className={classes.welcomeText}>
                WELCOME TO CUBAVIAGGIO.COM
            </Typography>
            <Typography className={classes.loadingPageText}>
                {page}
            </Typography>
          </header>
        </div>
    )
}