import React, {  Suspense } from 'react';
import {  Routes, Route} from 'react-router-dom';
import NotFoundPage from './components/pages/NotFoundPage';
import { createTheme, ThemeProvider} from '@material-ui/core';

import { Provider } from 'react-redux'
import store from './redux/store';

import LoadingComponent from './components/global/LoadingComponent';
// import SetStartItems from './components/global/SetStartItems';
// import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const HomePage = React.lazy(() => import('./components/pages/HomePage'));

const theme = createTheme({
  // palette: {
  //   primary: {
  //     main: colors.primary
  //   },
  //   secondary: {
  //     main: colors.secondary
  //   },
  // },
  // typography: {
  //   fontFamily: 'Montserrat',
  //   fontWeightLight: 400,
  //   fontWeightRegular: 500,
  //   fontWeightMedium: 600,
  //   fontWeightBold: 800
  // },
  // breakpoints: createBreakpoints({
  //   values: {
  //     xs: 0,
  //     sm: 640,
  //     md: 900,
  //     lg: 1140,
  //     xl: 1200,
  //   },
  // }),
})

function App() {
  // useEffect(() => {
  //   if (!("Notification" in window)) {
  //     console.log("This browser does not support desktop notification");
  //   }
  //   else if (Notification.permission === "granted") {
  //     // If it's okay let's create a notification
  //     subscribeUser()
  //   }
  //   else if (Notification.permission !== 'denied' || Notification.permission === "default") {
  //     Notification.requestPermission(function (permission) {
  //       // If the user accepts, let's create a notification
  //       if (permission === "granted") {
  //         console.log("Aceptadas las notificaciones")
  //         subscribeUser()
  //       }
  //     });
  //   }
  // },[]);
  
  return (
    <Provider store={store}>
      {/* <SetStartItems /> */}
      <ThemeProvider theme={theme}>
        
          <Routes>
            <Route path="/" exact element={
              <Suspense fallback={<LoadingComponent page={'LOADING HOME PAGE'}/>}>
                <HomePage />
              </Suspense> 
            }/>
            {/* <Route 
              path="/products/detail/:id"
              render={({ match }) => {
                  return(
                    // <Suspense fallback={<LoadingComponent page={'Cargando Página de Detalles...'}/>}>
                      <ProductDetailPage key={match.params.id || 'empty'} />
                    // </Suspense>
                  )
                }
              }
            /> */}
            
            
            {/* <Route path="/orders/:id?/:orderId?"
              render={({ match }) => {
                  return(
                      <OrdersPage key={`${match.params.id}${match.params.orderId}` || 'empty'} />
                  )
                }
              }
            /> */}
            
            <Route element={<NotFoundPage />} />
          </Routes>
        
      </ThemeProvider>
    </Provider>
   );
}

export default App;
